import { Paper, Stack } from "@mui/material"

export const QuoteFromJakob = () => {

    return (

        <Stack spacing={1}>

            <div>
                Jeg har været så heldig at have været i terapi hos Betina og dette forløb har været en væsentlig årsag til, at jeg den dag idag er ædru alkoholiker med 2 1/2 års ædruelighed bag mig.
            </div>

            <div>
                Vi har haft samtaler ca. en gang månedligt i terapiforløbet, og disse samtaler har været en uvurderlig hjælp for mig til at fastholde min ædruelighed.
            </div>

            <div>
                Terapien har givet mig brugbare værktøjer til at træffe de rigtige valg for mig, og til at sætte fokus på hvad der for mig er vigtigt her i livet.
            </div>

            <div>
                Forløbet har givet mig en anden og meget mere nuanceret indsigt i mig selv, og jeg ser mit samvær med mine medmennesker i et helt nyt og meget klarer lys.
            </div>

            <div>
                Jeg føler mig nu robust og viljestærk, og er ikke i tvivl om at jeg nu vil kunne gennemgå hvad livet må bringe af udfordringer i fremtiden, uden at ty til brugen af alkohol som en løsning.
            </div>

            <span>
                Jakob
            </span>

        </Stack>

    )

}