import { Paper, Stack } from "@mui/material"

export const QuoteFromThilde = () => {

    return (

        <Stack spacing={1}>

            <div>
                Mit forløb med Betina har været fuldstændigt livsændrende.
            </div>

            <div>
                Undervejs har jeg altid følt mig set, respekteret og forstået på en måde som jeg aldrig har oplevet før.
            </div>

            <div>
                Betina har lyttet og rummet mig fuldstændigt åbent og fordomsfrit. Betina har skabt et rum for mig, hvor jeg har følt så meget tryghed og tillid, at jeg har fundet modet til at åbne mig helt ærligt op og bearbejde mange traumer fra en utryg og kaotisk opvækst.
            </div>

            <div>
                Jeg har fået en helt ny selvindsigt, en helt uvurderlig følelse af ro og spirende tro på, at jeg er værdifuld. Mit liv og mine nære relationer er nu et langt bedre sted end jeg nogensinde havde turde håbe på.
            </div>

            <div>
                “Det er aldrig for sent, at give sig selv et nyt liv” læste jeg i en bog undervejs. Når jeg tænker på hvad jeg har opnået gennem forløbet med Betina, så rammer disse kloge ord helt plet.
            </div>

            <span>
                Thilde
            </span>

        </Stack>

    )

}