import { Paper, Stack } from "@mui/material"

export const QuoteFromCasper = () => {

    return (

        <Stack spacing={1}>

            <div>
                Igennem mit forløb med Betina er jeg blevet mere selvbevidst, og har fået værktøjer til at regulere mig selv.
            </div>

            <div>
                Dette har givet mit liv en helt ny betydning, og har gjort det meget nemmere at være mig selv.
            </div>

            <div>
                Jeg har oplevet massiv personlig udvikling igennem denne periode, og kan kun anbefale Betina og hendes professionelle tilgang.
            </div>

            <span>
                Casper
            </span>

        </Stack>

    )

}