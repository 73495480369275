import { faSquareCheck } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Avatar, Card, CardContent, CardHeader, Container, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, SvgIcon, Typography, useTheme } from "@mui/material"
import { AboutPageMd } from "./about/aboutMd";
import { AboutPageXs } from "./about/aboutXs";

export const AboutPage = () => {


    const theme = useTheme();


    return (
        <>
        <AboutPageMd />
        <AboutPageXs />
        </>
    )
}