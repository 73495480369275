import React from 'react'
import { AppBar, Box, IconButton, Stack, SvgIcon, Toolbar, Typography, useTheme } from '@mui/material'
import { NavTopMd } from './display/md'
import { ColorModeContext } from '../contexts/colorModeContext'
import { NavTopXs } from './display/xs'
import { Link } from 'react-router-dom'

export const NavTop = () => {
  const colorMode = React.useContext(ColorModeContext)

  const theme = useTheme();

  return (

    <React.Fragment>
      <AppBar >
        <Toolbar>

          <Stack width={1} spacing={1} direction={'row'} alignItems={'center'}
            justifyContent={'space-between'}>

              {/* <SvgIcon>
                <FontAwesomeIcon icon={faHome} />
              </SvgIcon> */}

            <Link to={'/'} style={{ textDecoration: 'none' }}>

              <Typography color={theme.palette.text.primary} >Psykoterapeut Betina Klysner</Typography>

            </Link>

            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              <NavTopMd />
              <NavTopXs />
              {/* 
              <IconButton onClick={colorMode.toggleColorMode} color="inherit">
                <FontAwesomeIcon icon={faCircleHalfStroke} />
              </IconButton>
 */}
            </Stack>

          </Stack>

        </Toolbar>
      </AppBar>
      <Toolbar />
    </React.Fragment>
  )
}
