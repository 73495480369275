import { Stack } from "@mui/material"
import { QuoteFromCasper } from "../welcome/quotes/casper"
import { QuoteFromJakob } from "../welcome/quotes/jakob"
import { QuoteFromThilde } from "../welcome/quotes/thilde"
import { BlockquoteXs } from "../../common/components/blockquote/xs"

export const QouteListXs = () => {


    return (

        <Stack pb={2} spacing={2} sx={{ display: { xs: 'flex', md: 'none' } }}>

            <BlockquoteXs>
                <QuoteFromThilde />
            </BlockquoteXs>

            <BlockquoteXs>
                <QuoteFromCasper />
            </BlockquoteXs>

            <BlockquoteXs>
                <QuoteFromJakob />
            </BlockquoteXs>

        </Stack>
    )
}