import Carousel from "react-material-ui-carousel"
import { Blockquote } from "../../common/components/blockquote"
import { QuoteFromCasper } from "../welcome/quotes/casper"
import { QuoteFromJakob } from "../welcome/quotes/jakob"
import { QuoteFromThilde } from "../welcome/quotes/thilde"

export const QuoteCarouselMd = () => {

    return (
        <Carousel
            navButtonsAlwaysVisible
            fullHeightHover
            height={450}
            sx={{
                display: { xs: 'none', md: 'block' }
            }}
        >
            <Blockquote>
                <QuoteFromThilde />
            </Blockquote>

            <Blockquote>
                <QuoteFromCasper />
            </Blockquote>

            <Blockquote>
                <QuoteFromJakob />
            </Blockquote>
        </Carousel>
    )
}