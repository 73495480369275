
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { FC } from "react";

const Quote = styled('blockquote')(`
margin: 0 !important;
font-style:italic;
color: #555555;
padding:3.2em 18px 1.2em 24px;
border-left:8px solid #78C0A8 ;
line-height:1.6;
position: relative;
background:#EDEDED;

&:before {
    font-family: Arial;
    content: "\\201C";
    color:#78C0A8;
    font-size: 4em;
    position: absolute;
    left: 10px;
    top: -10px;
}

&:after {
    content: '';

}

span {
    display: block;
    color:#333333;
    font-style: normal;
    font-weight: bold;
    margin-top: 1em;
}

`)


export const BlockquoteXs: FC<any> = ({ children }) => {

    return (
        <Box py={2}
            sx={{
                height: 1,
                display: 'flex',
                alignItems: 'center'
            }}
        >

            <Quote>
                {children}
            </Quote>
        </Box>

    )
}