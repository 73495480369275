import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'

import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import ErrorPage from './errorPage'
import Root from './routes/root'
import Home from './routes/home'
import { Contact } from './routes/contact'

import { aboutPathName, contactPathName, practicalInformationPathName  } from './routes'
import { AboutPage } from './routes/about'
import { PracticalInformationPage } from './routes/practical'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root/>,
    errorElement: <ErrorPage/>,
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: aboutPathName,
        element: <AboutPage/>
      },
      {
        path: contactPathName,
        element: <Contact/>
      },
      {
        path: practicalInformationPathName,
        element: <PracticalInformationPage/>
      }

    ]
  }

])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
