import { AppBar, Avatar, IconButton, Link as MuiLink, Stack, SvgIcon, Toolbar, Typography, useTheme } from '@mui/material'
import { ToolbarButton } from '../../toolbarButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { useState } from 'react'
import { facebookSvgPath } from '../../icons/facebook'
import { instagramSvgPath } from '../../icons/instagram'
import { Link, useLocation } from 'react-router-dom'
import { aboutPathName, contactPathName, homePathName, practicalInformationPathName } from '../../../routes'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

export const NavTopMd = () => {
  const location = useLocation()

  // const landscapeOriented = useMediaQuery('(orientation: landscape)')

  return (
    <Stack sx={{ display: { xs: 'none', md: 'flex' } }} spacing={5} direction={'row'}>

      <Link to={homePathName}>
        <ToolbarButton active={location.pathname === homePathName}>
          VELKOMMEN
        </ToolbarButton>
      </Link>

      <Link to={aboutPathName}>
        <ToolbarButton active={location.pathname === aboutPathName}>
          OM MIG
        </ToolbarButton>
      </Link>

      <Link to={practicalInformationPathName}>
        <ToolbarButton active={location.pathname === practicalInformationPathName}>
          PRAKTISK
        </ToolbarButton>
      </Link>

      <Link to={contactPathName}>
        <ToolbarButton active={location.pathname === contactPathName}
          startIcon={
            <SvgIcon sx={{ fontSize: '16px !important' }}>
              <FontAwesomeIcon icon={faEnvelope} />
            </SvgIcon>
          }
        >
          KONTAKT
        </ToolbarButton>
      </Link>

    </Stack>
  )
}

export const NavBottomMd = () => {
  const [hoveredText, setHoveredText] = useState('')

  // const facebookText = 'facebook.com/naest.arkitekter'
  // const instagramText = 'instagram.com/naestarkitekter'

  const theme = useTheme();

  return (

    <AppBar position="fixed" color="primary" sx={{
      display: { xs: 'none', md: 'block' },
      top: 'auto',
      bottom: 0,
      background: 'background.default'
    }}>
      <Toolbar>

        <Stack direction={'row'} alignItems={'center'} spacing={1}>



          <MuiLink sx={{ color: 'text.primary', textDecoration: 'none' }} href="tel:004560894803">

            <Stack direction={'row'} alignItems={'center'} spacing={1}>

              <Avatar sx={{ bgcolor: 'background.default' }}>

                <SvgIcon>
                  <FontAwesomeIcon color={theme.palette.text.primary} icon={faPhone} />
                </SvgIcon>
              </Avatar>

              <Typography >
                60 89 48 03
              </Typography>

            </Stack>

          </MuiLink>



          <Stack>
            {/* <Typography variant={'caption'}>© NAEST arkitekter 2023</Typography> */}
          </Stack>

          {/* <Stack spacing={1} direction={'row'} alignItems={'center'} position={'relative'} height={40}> */}

          {/*  <NotskalLogo /> */}
          {/*  <Typography variant={'caption'} fontSize={16}>Nötskal</Typography> */}

          {/* </Stack> */}

        </Stack>

        <Stack ml={'auto'} alignItems={'center'} direction={'row'} spacing={2}>





          {/* <Typography variant={'caption'}>
            {hoveredText}
          </Typography> */}

          {/* <IconButton href={'https://www.facebook.com/naest.arkitekter'} target={'_blank'}
            onMouseEnter={() => {
              setHoveredText(facebookText)
            }}
            onMouseLeave={() => {
              setHoveredText('')
            }}>
            <SvgIcon fontSize={'small'} viewBox={'0 0 512 512'}>
              {facebookSvgPath}
            </SvgIcon>
          </IconButton> */}

          {/* <IconButton href={'https://www.instagram.com/naestarkitekter'} target={'_blank'}
            onMouseEnter={() => {
              setHoveredText(instagramText)
            }}
            onMouseLeave={() => {
              setHoveredText('')
            }}>
            <SvgIcon fontSize={'small'} viewBox={'0 0 512 512'}>
              {instagramSvgPath}
            </SvgIcon>
          </IconButton> */}

        </Stack>

      </Toolbar>

    </AppBar>
  )
}
