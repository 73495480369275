import React, { useState } from 'react'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Link,
  Paper,
  Stack,
  SvgIcon,
  TextField,
  Typography,
  useTheme
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPaperPlane } from '@fortawesome/free-regular-svg-icons'
import { LoadingButton } from '@mui/lab'
import axios from 'axios'
import { faPhone, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons'

export interface ContactFormInfo {
  error: boolean
  msg: string | null
}

export interface ContactFormStatus {
  submitted: boolean
  submitting: boolean
  info: ContactFormInfo
  errors: ContactFormError[]
}

export interface ContactFormError {
  code: string
  field: string
  message: string
}

export const Contact = () => {
  const theme = useTheme()

  const [status, setStatus] = useState<ContactFormStatus>({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
    errors: []
  })
  const [inputs, setInputs] = useState({
    email: '',
    message: ''
  })
  const handleServerResponse = (ok: boolean, msg: string, errors: ContactFormError[] = []) => {
    if (ok) {
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg },
        errors
      })
      setInputs({
        email: '',
        message: ''
      })
    } else {
      setStatus(prev => ({
        ...prev,
        submitting: false,
        info: { error: true, msg },
        errors
      }))
    }
  }
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.persist()
    setInputs((prev) => ({
      ...prev,
      [e.target.id]: e.target.value
    }))
    setStatus({
      submitted: false,
      submitting: false,
      info: { error: false, msg: null },
      errors: []
    })
  }
  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setStatus(prevStatus => ({ ...prevStatus, submitting: true }))
    axios({
      method: 'POST',
      url: 'https://formspree.io/f/mbjvwzzy',
      data: inputs
    })
      .then((response) => {
        handleServerResponse(
          true,
          'Tak - din besked er nu sendt!'
        )
      })
      .catch((error) => {
        handleServerResponse(false, error.response.data.error, error.response.data.errors)
      })
  }

  return (
    <Container sx={{ height: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

      <Stack sx={{ width: { xs: 1, md: 'auto' } }} spacing={4}>

        <Box position={'relative'} sx={{
          pointerEvents: 'none'
        }}>

          <Card sx={{ position: 'relative' }}>
            <CardHeader
              avatar={
                // <Avatar sx={{ bgcolor: 'background.paper' }}>
                <SvgIcon>
                  <FontAwesomeIcon color={theme.palette.text.primary} icon={faEnvelope} />
                </SvgIcon>
                // </Avatar>
              }
            />
            <CardContent>

              <Box
                component="form"
                id={'contactForm'}
                onSubmit={handleOnSubmit}
                sx={{
                  '& > :not(style)': { width: { xs: '100%', md: 500 } }
                }}
                noValidate
                autoComplete="off"
              >


                <Stack spacing={1}>
                  <TextField
                    InputLabelProps={{
                      style: {
                        color: theme.palette.text.secondary
                      }
                    }}
                    label="E-mail"
                    variant="filled"
                    id="email"
                    type="email"
                    name="_replyto"
                    onChange={handleOnChange}
                    required
                    value={inputs.email}
                  />

                  <TextField
                    InputLabelProps={{
                      style: {
                        color: theme.palette.text.secondary
                      }
                    }}
                    multiline
                    rows={6}
                    variant="filled"
                    label="Besked"
                    id="message"
                    name="message"
                    onChange={handleOnChange}
                    required
                    value={inputs.message}
                  />

                </Stack>

              </Box>

              <Stack spacing={1}>

                {!status.info.error && status.info.msg && (
                  <Typography>
                    <p>{status.info.msg}</p>
                  </Typography>
                )}

                {status.errors.map(e => {
                  return (
                    <Stack key={e.code} spacing={1} direction={'row'}>
                      <Typography color={'error.main'}>{`Error in '${e.field}':`}</Typography>
                      <Typography color={'error.main'}>{e.message}</Typography>
                    </Stack>
                  )
                })}

              </Stack>

            </CardContent>

            <CardActions>
              <LoadingButton
                color={'info'}
                sx={{ ml: 'auto' }}
                type={'submit'}
                form={'contactForm'}
                endIcon={
                  <SvgIcon>
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </SvgIcon>
                }
                loading={status.submitting}
                loadingPosition="end"
                variant="contained"
              >
                <span>Send</span>
              </LoadingButton>
            </CardActions>


            <Box sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: 'rgba(0,0,0,0.4)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <Paper sx={{ py: 1, px: 3, transform: 'rotate(-15deg)', background: theme.palette.warning.light }}>

                <Stack color={'white'} spacing={2} direction={'row'} display={'flex'} alignItems={'center'}>

                  <SvgIcon>
                    <FontAwesomeIcon icon={faScrewdriverWrench} />
                  </SvgIcon>
                  <Typography variant={'h5'}>
                    Under udarbejdelse
                  </Typography>

                </Stack>

              </Paper>
            </Box>

          </Card>
        </Box>



        <Link sx={{ color: 'text.primary', textDecoration: 'none' }} href="tel:004560894803">

          <Stack direction={'row'} alignItems={'center'} spacing={1}>

            {/* <Avatar sx={{ bgcolor: 'background.default' }}> */}
            <SvgIcon>
              <FontAwesomeIcon color={theme.palette.text.primary} icon={faPhone} />
            </SvgIcon>
            {/* </Avatar> */}

            <Typography >
              60 89 48 03
            </Typography>

          </Stack>

        </Link>

      </Stack>

    </Container>
  )
}
