import React from 'react'
import { Box, Container, Grid, Paper, Stack, Typography } from '@mui/material'
import { Blockquote } from '../common/components/blockquote'
import Carousel from 'react-material-ui-carousel'
import { QuoteFromThilde } from './welcome/quotes/thilde'
import { QuoteFromCasper } from './welcome/quotes/casper'
import { QuoteFromJakob } from './welcome/quotes/jakob'
import LogoSvg from '../svg/logo'
import { QuoteCarouselMd } from './home/quoteCarouselMd'
import { QouteListXs } from './home/quoteListXs'

export default function Home() {
  return (

    <Container sx={{ pt: 4, pb: 5 }}>

      <Stack spacing={2} direction={'row'}>



        <Stack spacing={2}>

          <Stack position={'relative'} spacing={3} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>

            <Paper>
              <Stack spacing={2} p={2}>
                <Typography variant={'body2'} >
                  Oplever du ofte tristhed i stedet for glæde og velvære?
                </Typography>
                <Typography variant={'body2'} >
                  Føler du dig forkert eller tænker, at der er noget galt med dig?
                </Typography>
                <Typography variant={'body2'} >
                  Har du ubehagelige oplevelser, der følger med fra fortiden og som forstyrrer dig i din nutid?
                </Typography>
                <Typography variant={'body2'} >
                  Er du pårørende til misbrugere eller psykisk syg?
                </Typography>
              </Stack>
            </Paper>

            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <LogoSvg />
            </Box>

          </Stack>



          <Typography variant={'h6'} fontWeight={'bold'} py={2}>
            Jeg tilbyder samtaleterapi som vil styrke dine kompetencer til at håndtere de konkrete udfordringer du står i.
          </Typography>



        </Stack>





      </Stack>


      <Stack spacing={2} pb={2}>

        <Typography>
          Hvis du sidder med en overvejelse eller måske en frygt for om hvorvidt du overhovedet kan få det bedre - så skal du vide at det er muligt at ændre.
          Du skal vide at det er muligt at skabe sig et liv, hvor også du oplever glæde og tilpashed i krop og sind - og du skal vide at det, er det jeg kan tilbyde at støtte dig i.
        </Typography>

        <Typography>
          Jeg har talt med mange mennesker, som havde det svært og som ikke havde tro på det var muligt at få det bedre.
        </Typography>

        <Typography>
          Mennesker, som nu, med det jeg byder på, har fået måder at hjælpe sig på og dermed skaber sig det gode liv -de så inderligt ønsker for sig.
          Hvis du lige nu sidder med forståelsen af -at det vil være for svært at få det godt -Så skal du vide at andre, jeg har talt med, er blevet overrasket over hvor hurtigt de oplevede at deres besvær og vanskeligheder blev mindre og enklere at håndtere - og at de fik det bedre.
        </Typography>

        <Typography>
          Du skal vide at det de fik med sig var at deres arbejdsindsats for det gode liv var langt enklere og nemmere end de troede. Og du skal vide, at det er jeg kan hjælpe med -og at det du vil få i vores samarbejde er- at få det bedre.
        </Typography>

      </Stack>


<QuoteCarouselMd />

<QouteListXs />

     

    </Container >
  )
}
